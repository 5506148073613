<script setup>
import { decode } from 'blurhash'

const props = defineProps({
	src: {
		type: String,
		required: true
	},
	alt: {
		type: String,
		required: true
	},
	blurhash: {
		type: String,
		default: 'LAIz-QDN5D9y}U9Fbv?a9GM_I=OY'
	},
	width: {
		type: Number,
		required: false,
		default: null
	},
	height: {
		type: Number,
		required: false,
		default: null
	},
	preload: {
		type: Boolean,
		required: false,
		default: false
	},
	focalPoint: {
		type: String,
		default: ''
	}
})

const {
	blurhash,
	width,
	height,
	src,
	alt,
	preload,
	focalPoint
} = toRefs(props)

const widthValue = computed(() => width.value || getImageDimensions(src.value).width)
const heightValue = computed(() => height.value || getImageDimensions(src.value).height)

const observer = ref(null)
const container = ref(null)
const canvas = ref(null)
const picture = ref(null)
const loaded = ref(false)

const setBlurHash = () => {
	if (!blurhash.value) {
		return
	}

	const pixels = decode(blurhash.value, 32, 32)
	const context = canvas.value.getContext('2d')
	const imageData = context.createImageData(32, 32)

	imageData.data.set(pixels)
	context.putImageData(imageData, 0, 0)
}

const observeIntersection = () => {
	observer.value = new IntersectionObserver(
		([entry]) => {
			if (entry.isIntersecting) {
				const imgElement = picture.value?.$el.querySelector('img')
				if (imgElement?.complete) {
					loaded.value = true
				} else {
					imgElement.addEventListener('load', () => {
						loaded.value = true
					})
				}

				observer.value.unobserve(container.value)
			}
		},
		{
			threshold: 0.1
		}
	)

	observer.value.observe(container.value)
}

onMounted(() => {
	setBlurHash()
	observeIntersection()
})

onBeforeUnmount(() => {
	if (observer.value) {
		observer.value.disconnect()
	}
})
</script>

<template>
	<div
		ref="container"
		:class="{ loaded }"
		class="image-element"
		:style="{
			'--image-width': `${widthValue}px`,
			'--image-height': `${heightValue}px`,
			'--aspect-ratio': `${widthValue / heightValue}`,
			'--object-position': focalPoint && getFocusByDimensions(
				focalPoint,
				{
					width: widthValue,
					height: heightValue
				}
			)
		}"
	>
		<NuxtPicture
			ref="picture"
			:src="src"
			:alt="alt"
			:width="width"
			:height="height"
			:preload="preload"
			:loading="preload ? 'eager' : 'lazy'"
			:modifiers="{
				smart: true,
				filters: {
					focal: focalPoint
				}
			}"
			class="picture-element"
			provider="storyblok"
			@load="loaded = true"
		/>
		<canvas
			ref="canvas"
			height="32"
			width="32"
		/>
	</div>
</template>

<style scoped>
.image-element {
	pointer-events: none;
	user-select: none;

	position: var(--image-position, relative);

	aspect-ratio: var(--aspect-ratio, auto);

	transition-duration: var(--transition-duration);
	transition-property: opacity;

	> * {
		position: absolute;
		z-index: 1;
		inset: 0;

		width: 100%;
		height: 100%;
		margin: auto;
	}

	.picture-element {
		opacity: var(--image-opacity, 0);
		transition: opacity calc(var(--transition-duration) * 2) ease-in-out;

		&.picture-element,
		:deep(> img) {
			aspect-ratio: var(--aspect-ratio, auto);
			object-fit: cover;
			object-position: var(--object-position, center);
		}

		:deep(img) {
			width: 100%;
			height: 100%;
		}
	}

	> canvas {
		z-index: 0;
	}

	&.loaded {
		--image-opacity: 1;
		--image-position: relative;
	}
}
</style>
